.title-and-filters-container {
  position: sticky;
  top: 0;
  padding-top: 16px;
  background: #fff;
  z-index: 100;
}

.title-and-filters-container.should-not-stick {
  position: unset;
  padding-top: unset;
}

.preview-table {
  display: grid;
  grid-template-columns: repeat(var(--col-count), auto);
  border: solid 2px #e2e8f0;
  border-radius: 8px;
  background: #f0f3f6;
  width: var(--table-width, 100%);
  height: var(--table-height, auto);
  overflow: var(--table-overflow, auto);
  grid-auto-rows: max-content;
}

.preview-table .table-header {
  padding: 8px;
  background: #f0f3f6;
  text-transform: uppercase;
  white-space: nowrap;
  min-width: 120px;
  width: max-content;
}

.preview-table .table-header:not(:first-child) {
  border-left: solid 1px #e2e8f0;
}

.preview-table > :not(.table-header) {
  padding: 16px 8px;
  border-bottom: 1px solid #e2e8f0;
  background: #fff;
  min-height: calc(1em + 16px * 2);
  line-height: 1;
}

.dashboard-container > .ratio-5-7 {
  --col-template: minmax(0, 5fr) minmax(0, 7fr);
}

.dashboard-container .chart-section {
  border-right: var(--border-right);
}

@media screen and (max-width: 900px) {
  .dashboard-container .chart-section {
    --border-right: none;
    padding: 16px 0;
  }
  .dashboard-container > [class*="col-container"] {
    --col-template: minmax(0, 1fr);
  }
}

.show-first, .show-second, .show-third {
  opacity: 0;
  animation: fade-in-opacity 1s cubic-bezier(0.45, 0, 0.55, 1) forwards;
  animation-delay: 5s;
}
.show-second {
  animation-duration: .6s;
  animation-delay: 7s;
}
.show-third {
  animation-duration: .6s;
  animation-delay: 8s;
}

@keyframes fade-in-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.logo-container {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;

  &.small {
    height: 16px;
    width: 16px;
  }
}
