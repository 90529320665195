/* THIS IS A TEMP SOLUTION. WHEN WE HAVE DATA-ROOM RUNNING WE SHOULD REFACTOR */

.employee-form-container > form > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

.employee-form-container > form > div > div {
  grid-column: span 3;
}

.employee-form-container .survey-input-textfield {
  grid-column: span 1;
}

.employee-form-container :is(.survey-input-textfield, .survey-input-slider) label {
  font-weight: 400;
}

.employee-form-container .survey-input-header:not(:first-child) {
    margin-top: 40px;
}
