.MuiPopover-paper {
    border: 1px solid var(--background-colors-outline-light, #E2E8F0);
    border-radius: 4px;
    box-shadow: var(--shadow-medium);
}

.dropdown-menu {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
}

.dropdown-menu .nav-btn {
    border-radius: 4px;
    flex-wrap: nowrap;
    gap: 8px;
    justify-content: start;
    overflow: hidden;
    padding: 8px;
    white-space: nowrap;
    width: 100%;
}

.dropdown-menu .nav-btn.workspace-item {
    color: var(--primary-colors-primary);
    background-color: var(--primary-colors-primary-light);;

}

.dropdown-menu .nav-btn .btn-text {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.dropdown-menu .inactive svg {
    color: var(--icon-grey);
}

.dropdown-menu .nav-btn:focus-visible:after {
    border-radius: 4px;
    content: '';
    height: 100%;
    left: 0;
    outline: 2px solid var(--focus-ring-color);
    outline-offset: -2px;
    position: absolute;
    top: 0;
    width: 100%;
}