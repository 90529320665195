.layout-snackbars {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9500;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 16px;
    padding: 0 0 40px calc(var(--user-nav-full-width) + 40px);
    transition: padding 0.3s;
}

.layout-snackbar {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 12px 16px;
    display: grid;
    gap: 8px;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    min-width: 300px;
}

.layout-snackbar.is-error {
    border-color: #F50F4A;
}

.layout-snackbar.is-success {
    animation: wiggle-wiggle 0.5s ease-in-out;
}

@keyframes wiggle-wiggle {
    0%, 100% {
        transform: translateX(0);
    }
    25%, 75% {
        transform: translateX(4px);
    }
    50% {
        transform: translateX(-4px);
    }
}