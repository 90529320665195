.drag-container,
.drag-in-container {
  width: 120px;
  height: 148px;
  border-radius: 8px;
  background: var(--bg-color, #f0f3f6);
  position: relative;
  flex-shrink: 0;
}

:is(.drag-container, .drag-in-container) [draggable] {
  height: 100%;
  --table-height: 100%;
  cursor: grab;
}

.drag-in-container {
  height: 72px;
}

.drag-in-container > p {
  width: 100%;
  text-align: center;
  position: absolute;
  top: calc(50% - 0.5em);
}

.drag-in-container.is-error {
  --bg-color: #fee7ed;
  border: 1px solid #f50f4a;
}

.drag-column {
  grid-template-rows: auto minmax(0, 1fr);
  height: clamp(200px, 40vh, 500px);
  background: #F0F3F6;
  border-radius: 8px;
  padding-block: 16px;
}

.drag-column.is-drag-over{
  background: #F1F7FF;
}

.drop-col-item {
    cursor: grab;
    position: relative;
}

.drop-col-item.is-dragged:after {
    content: var(--selected-amount);
    position: absolute;
    top: 0;
    right: 0;
    padding: 1px 4px;
    font-size: 10px;
    color: white;
    border-radius: 0 4px;
    background: #1E90FF;
}

.drop-col-item > * {
    border: 1px solid transparent;
}

.drop-col-item:is(.is-selected, :active) > * {
    background: #F1F7FF;
    border-color: #1E90FF;
}