.coolset-logo-loader {
  --logo-size: 64px;
  --logo-timing: 2.5s;
  display: flex;
}

.coolset-logo-loader > div {
  height: var(--logo-size);
  width: calc(var(--logo-size) / 2);
  background: var(--logo-bg-color, #1e78ff);
  border-radius: 0 var(--logo-size) var(--logo-size) 0;
  margin-right: calc(var(--logo-size) * -0.2);
  animation: pice-bounce var(--logo-timing) infinite linear;
}

.coolset-logo-loader > div:nth-child(1) {
  z-index: 3;
}
.coolset-logo-loader > div:nth-child(2) {
  --logo-bg-color: #2f43ff;
  z-index: 2;
  animation-delay: calc(var(--logo-timing) / 6);
}
.coolset-logo-loader > div:nth-child(3) {
  --logo-bg-color: #060850;
  margin-right: 0;
  animation-delay: calc(var(--logo-timing) / 3);
}

.coolset-page-loader {
  position: absolute;
  inset: 0;
  background: #fff;
  z-index: 50;
  transform: scale(0);
  opacity: 0;
  transition: var(--loader-transition, none);
}

.coolset-page-loader.is-open {
  transform: scale(1);
  opacity: 1;
}

@keyframes pice-bounce {
  0% {
    translate: 0;
  }
  10% {
    translate: 0 15%;
  }
  20% {
    translate: 0 -5%;
  }
  25% {
    translate: 0 2%;
  }
  30%,
  100% {
    translate: 0;
  }
}
