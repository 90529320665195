:root {
    --user-nav-px: 24px;
    --user-nav-py: 24px;
    --user-nav-base-width: 252px;
    --user-nav-full-width: calc(var(--user-nav-base-width) + var(--user-nav-px) * 2);
}

.user-nav {
    --t1: 300ms;
    --t2: 200ms;

    --container-transition: var(--t1) width, var(--t1) padding;
    --logo-transition: var(--t1) margin-right, var(--t1) width;
    --icon-transition: var(--t1) transform, var(--t1) top;

    --company-icon-size: 24px;
    --company-icon-collapse-offset: 40px;
    --company-icon-collapse-negative-offset: -40px;
    --collapsed-icon-size: 32px;
    --sidebar-icon-size: 16px;

    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    width: var(--user-nav-full-width);
    background: #F9FAFE;
    padding: var(--user-nav-py) var(--user-nav-px);
    border-right: 1px solid #E2E8F0;
    transition: var(--container-transition);
    overflow: hidden;
}

:root:has(.user-nav.is-collapsed) {
    --user-nav-px: 16px;
    --user-nav-base-width: 32px;
}

.user-nav .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    row-gap: 12px;
    padding-left: 4px;
    padding-top: 0;
    transition: var(--t1) padding-top;
}

.user-nav.is-collapsed .header {
    padding-top: var(--company-icon-collapse-offset);
}

.user-nav .header button {
    border-radius: 4px;
    padding: 4px;
    position: relative;
    right: 0;
    transform: rotate(360deg);
    transition: var(--icon-transition), var(--t1) right;
    top: 0;
}

.user-nav.is-collapsed .header button {
    right: var(--collapsed-icon-size);
    transform: rotate(180deg);
    top: var(--company-icon-collapse-negative-offset);
}

.user-nav .header svg {
    color: var(--icon-grey);
}

.user-nav .logo {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 8px;
    overflow: hidden;
    min-width: var(--company-icon-size);
    width: calc(var(--user-nav-base-width) - 32px - 24px);
    transition: var(--t1) width;
}

.user-nav.is-collapsed .logo {
    width: var(--collapsed-icon-size);
}

.user-nav .logo h5 {
    margin: 0;
    padding-left: 8px;
}

.user-nav .logo img {
    max-height: var(--company-icon-size);
    min-height: 20px;
    max-width: 100%;
    width: auto;
}

.user-nav .nav-btn {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    box-shadow: none;
    justify-content: start;
    gap: 16px;
    padding-inline: 8px;
    transition: var(--t1) width;
}

.user-nav.is-collapsed .nav-btn {
    width: var(--collapsed-icon-size);
}

.user-nav .inactive svg {
    color: var(--icon-grey);
}

.user-nav :is(.btn-text, h6) {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    transition: var(--t1) color;
}

.user-nav.is-collapsed :is(.btn-text, h6) {
    color: transparent;
}