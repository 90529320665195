:root {
  --primary-colors-primary: #1E78FF;
  --primary-colors-primary-light: #DFECFF;

  --icon-grey: #9098A7;
  
  --focus-ring-color: var(--primary-colors-primary);

  --shadow-medium: 0px 1px 4px 0px rgba(55, 65, 81, 0.10), 0px 0px 2px 0px rgba(55, 65, 81, 0.05);
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:link,
a:visited {
  text-decoration: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.one-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capitalize-first::first-letter {
  text-transform: capitalize;
}

.bold-font {
  font-weight: 600;
}

.is-hide {
  display: none !important;
}

.is-hide-data-grid {
  position: absolute;
  scale: 0 0;
}

.full-page-layout {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100vh;
}

.fadein-at-render {
  opacity: 0;
  animation: fadein 0.8s forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: var(--opacity, 1);
  }
}