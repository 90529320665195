.steps-container {
  --size: 24px;
  --bar-height: 4px;
  --padding-x: 80px;
  --font-size: 16px;
  --bottom-space: calc(1em + 16px);
  --main-color: #1E78FF;
  --sub-color: #DFECFF;

  font-size: var(--font-size, 1em);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--padding-x);
  padding-bottom: var(--bottom-space);
}

.steps-container .steps-item {
  display: grid;
  place-items: center;
  position: relative;
  background: var(--sub-color);
  color: var(--main-color);
  border-radius: 50%;
  height: var(--size);
  width: var(--size);
}

.steps-container .steps-item :is(img, svg) {
    height: calc(var(--size) - 8px);
    width: calc(var(--size) - 8px);
}

.steps-container .steps-item h6 {
  position: absolute;
  bottom: calc(var(--bottom-space) * -1);
  left: 50%;
  margin: 0;
  translate: -50% 0;
  font-size: inherit;
  white-space: nowrap;
}

.steps-container .steps-bar {
  height: var(--bar-height, calc(var(--size) / 3));
  background: var(--sub-color);
  flex: 1 0 100px;
  margin: 0 calc(var(--size) / -15);
  max-width: 200px;
}

.steps-container .steps-item.is-completed {
    background: var(--main-color);
    color: #fff;
}

.steps-container .steps-item.is-completed + .steps-bar + .steps-item{
  border: solid 1px var(--main-color);
}

.steps-container .steps-item.is-completed + .steps-bar {
    background: var(--main-color);
}
